import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    electronListUrl: ''
  },
  mutations: {
    changeToken(state, val) {
      state.access_token = val
    },
    changeElectronListUrl(state, val) {
      state.electronListUrl = val
    },
    loading(state, val) {
      state.loading = val
    }
  },
  actions: {
    
  },
  modules: {}
})