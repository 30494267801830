<template>
  <div id="app">
    <keep-alive>
      <!-- 需要缓存的视图组件 -->
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- 不需要缓存的视图组件 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- <router-view /> -->
  </div>
</template>
<script>
export default {
  mounted() {},
  methods: {
    requestBackstage() {
      this.$ajax.post("/api/worker/orders").then((res) => {
        console.log(res);
        this.homeAllOrder = res.data.orders;
        // Toast("刷新成功");
        this.isLoading = false;
      });
    },
  },
};
</script>>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}

html {
  background: #f1f0f5;
  font-size: calc(100vw / 37.5);
}
</style>
