const {
  default: router
} = require("./router");

/** 公共域名 */
exports.install = (Vue, options) => {
  Vue.prototype.cdnHttp = 'https://cdn.yishifu.cn/'
  Vue.prototype.yishifuHttp = 'https://api.yishifu.com'
  Vue.prototype.static_url = 'http://static.yishifu.com/'
  Vue.prototype.http_axios = 'https://api.yishifu.com'


  /** 公共媒体资源 */
  Vue.prototype.MEDIA_URL = {
    'logo_theme': 'https://cdn.yishifu.cn/image/sg3oe7VWE1ntd4RbjveXzSdiqw2N2HvtKNwvXZxz.png',
    'logo': 'https://cdn.yishifu.cn/image/3eegv5BueOIQclDTvc21mmCocAY4EiAus7iZi5Pn.png',
    'avatar': 'http://static.yishifu.com/worker/avatar.png'
  }
  /** 时间天数

   */

  /** 
   * 师傅端时间倒计时管理 
   * @clear_order 订单报价倒计时 - 天 
   *  1.订单详情-师傅报价
   *  2.订单列表
   * @confirm_refund_day 最迟师傅同意退款时间 - 天
   */
  Vue.prototype.confirm_refund_day = 5
  Vue.prototype.clear_order = 1
  /** 
   * 师傅端状态管理
   * @order_status 订单状态
   * @work_status 师傅状态
   */
  Vue.prototype.order_status = [{
        status: 0,
        message: "等待报价",
      },
      {
        status: 1,
        message: "师傅已报价",
      },
      {
        status: 2,
        message: "已指派您",
      },
      {
        status: 3,
        message: "已托管费用",
      },
      {
        status: 4,
        message: "师傅过程",
      },
      {
        status: 5,
        message: "施工完成/等待验收",
      },
      {
        status: 6,
        message: "验收不合格",
      },
      {
        status: 7,
        message: "验收通过/等待确认打款",
      },
      {
        status: 8,
        message: "已打款/待评价",
      },
      {
        status: 9,
        message: "评价完成",
      },
      {
        status: -1,
        message: "其他异常",
      },
      {
        status: -2,
        message: "关闭订单",
      },
    ],
    Vue.prototype.work_status = [{
        status: 0,
        message: "商家已下单",
      },
      {
        status: 1,
        message: "待预约",
      },
      {
        status: 2,
        message: "预约失败",
      },
      {
        status: 3,
        message: "预约成功",
      },
      {
        status: 4,
        message: "待提货",
      },
      {
        status: 5,
        message: "提货失败",
      },
      {
        status: 6,
        message: "提货成功/待签到",
      },
      {
        status: 7,
        message: "签到失败",
      },
      {
        status: 8,
        message: "已签到/待验货",
      },
      {
        status: 9,
        message: "验货异常",
      },
      {
        status: 10,
        message: "验货成功/待完工",
      },
      {
        status: 11,
        message: "完工失败",
      },
      {
        status: 12,
        message: "完工成功/待验收",
      },
      {
        status: 13,
        message: "验收失败",
      },
      {
        status: 14,
        message: "验收成功",
      },
      {
        status: 15,
        message: "待退款",
      },
      {
        status: 16,
        message: "退款成功",
      },
      {
        status: 17,
        message: "交易成功",
      },
      {
        status: -1,
        message: "验货成功/待完工",
      },
      {
        status: -2,
        message: "关闭订单",
      },
    ],
    Vue.prototype.refund_states = [{
        status: 0,
        message: '王八',
      },
      {
        status: 1,
        message: '退款申请中',
      },
      {
        status: 2,
        message: "退款成功",
      },
      {
        status: 3,
        message: "退款成功",
      },
      {
        status: 4,
        message: "退款失败",
      },
      {
        status: -1,
        message: "取消退款",
      },
    ]

  Vue.prototype.verified_states = [{
      id: 0,
      name: '等待审核'
    },
    {
      id: 1,
      name: '审核通过'
    },
    {
      id: 2,
      name: '审核失败'
    },
    {
      id: -1,
      name: '撤销审核'
    },
  ]

  /** 
   * 实名认证状态管理
   * @verified-states 0 待审核 1 通过 2 未通过 - 1 撤销审核
   */
  /** 
    
   监听滚动条事件
  */
  Vue.prototype.getScrolltop = () => {
    document.addEventListener('scroll', () => {
      let _SCROLL_TOP = document.documentElement.scrollTop || document.body.scrollTop;
      return _SCROLL_TOP
    })
  }

  // fun_date(7); //7天后的日期
  // fun_date(-7); //7天前的日期
  Vue.prototype.getFutureDate = (day) => {
    var today = new Date();
    var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
    today.setTime(targetday_milliseconds); //注意，这行是关键代码
    var tYear = today.getFullYear();
    var tMonth = today.getMonth();
    var tDate = today.getDate();
    tMonth = doHandleMonth(tMonth + 1);
    tDate = doHandleMonth(tDate);
    return tMonth + "-" + tDate;
  }

  function doHandleMonth(month) {
    var m = month;
    if (month.toString().length == 1) {
      m = "0" + month;
    }
    return m;
  }
  // 跳转下一页面
  Vue.prototype.goNextPage = (path) => {
    router.push(path)
  }
  // 跳转前一页
  Vue.prototype.goLastPage = () => {
    router.go(-1)
  }
  Vue.prototype.goSubPage = (names, ids) => {
    router.push({
      name: names,
      params: {
        orderId: ids
      }
    })
  }
  Vue.prototype.goSubPageR = (names, ids) => {
    router.replace({
      name: names,
      params: {
        orderId: ids
      }
    })
  }

  Array.prototype.remove = function (val) {
    var index = this.indexOf(val);
    if (index > -1) {
      this.splice(index, 1);
    }
  };

  Vue.prototype.getMateDate = (date) => {
    var json_date = new Date(date).toJSON();
    return new Date(new Date(date) + 8 * 3600 * 1000)
      .toISOString()
      .replace(/T/g, " ")
      .replace(/\.[\d]{3}Z/, "");
  }
  // 将base64 改为file格式
  Vue.prototype.convertBase64UrlToBlob = (urlData) => {
    var bytes = window.atob(urlData.split(",")[1]); //去掉url的头，并转换为byte
    //处理异常,将ascii码小于0的转换为大于0
    var ab = new ArrayBuffer(bytes.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < bytes.length; i++) {
      ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ab], {
      type: "image/jpeg"
    });
  }
  /**
   * 地址定位
   */
  Vue.prototype.getLocation = () => {
    const self = this;
    AMap.plugin("AMap.Geolocation", () => {
      var geolocation = new AMap.Geolocation({
        // 是否使用高精度定位，默认：true
        enableHighAccuracy: true,
        // 设置定位超时时间，默认：无穷大
        timeout: 10000,
      });
      geolocation.getCurrentPosition();
      AMap.event.addListener(geolocation, "complete", onComplete);
      AMap.event.addListener(geolocation, "error", onError);

      function onComplete(data) {
        // data是具体的定位信息
        console.log("定位成功信息：", data);
        let reg = /.+?(省|市|自治区|自治州|县|区)/g;
        return data
        self.userPosition = data.formattedAddress.match(reg)[0];
        console.log(self.userPosition);
      }

      function onError(data) {
        // 定位出错
        console.log("定位失败错误：", data);
        // 调用ip定位
        self.getLngLatLocation();
      }
    });
  }
  Vue.prototype.getLngLatLocation = () => {
    const self = this;
    AMap.plugin("AMap.CitySearch", function () {
      var citySearch = new AMap.CitySearch();
      citySearch.getLocalCity(function (status, result) {
        if (status === "complete" && result.info === "OK") {
          // 查询成功，result即为当前所在城市信息
          console.log("通过ip获取当前城市：", result);
          self.userPosition = result.city;
          //逆向地理编码
          AMap.plugin("AMap.Geocoder", function () {
            var geocoder = new AMap.Geocoder({
              // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
              city: result.adcode,
            });
            var lnglat = result.rectangle.split(";")[0].split(",");
            geocoder.getAddress(lnglat, function (status, data) {
              if (status === "complete" && data.info === "OK") {
                // result为对应的地理位置详细信息
                console.log(data);
              }
            });
          });
        }
      });
    });
  }
}