<template>
  <div :class="`top-nav-bar ${theme}`">
    <div class="nav-left flexCenter" @click="clickLeft">
      <span>{{left_text}}</span>
      <slot name="left" v-if="left_text == ''">
      </slot>
    </div>
    <div class="nav-center flexCenter" @click="clickCenter">
      <slot name="center">

      </slot>
    </div>
    <div class="nav-right flexCenter" @click="clickRight">
      <slot name="right">

      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    left_text: {
      type: String,
      default: "",
    },
    right_text: {
      type: String,
      default: "返回",
    },
    theme: {
      type: String,
      default: "color",
    },
  },
  methods: {
    clickLeft() {
      this.$emit("clickLeft");
    },
    clickRight() {
      this.$emit("clickRight");
    },
    clickCenter() {
      this.$emit("clickCenter");
    },
  },
};
</script>

<style lang="less">
.top-nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: 46px;
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  &.white {
    background: #fff;
    color: #000;
  }
  &.color {
    background: @themeColor;
    color: seashell;
  }
  .nav-left,
  .nav-right {
    box-sizing: border-box;
    width: 6rem;
    white-space: nowrap;
  }

  .nav-center {
    flex: 1;
    font-weight: 600;
    img {
      width: 9rem;
    }
  }
}
</style>