import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'

Vue.use(VueRouter)
const routes = [{
    path: '/',
    name: 'home',
    component: () => import('../views/home/index'),
    meta: {
      keepAlive: true,
      title: '首页'
    }
  },
  {
    path: '/home/message',
    name: 'message',
    component: () => import('../views/home/message/index'),
    meta: {
      title: '消息'
    }
  },
  {
    path: '/member/register',
    name: 'register',
    component: () => import('../views/member/register/index'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/member/login',
    name: 'login',
    component: () => import('../views/member/login/index'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/member/reset',
    name: 'reset_password',
    component: () => import('../views/member/reset/index'),
    meta: {
      title: '重置密码'
    }
  },
  {
    path: '/member/certification',
    name: 'certification',
    component: () => import('../views/member/certification/index'),
    meta: {
      title: '实名认证'
    }
  },
  {
    path: '/member/certification/name-pass',
    name: 'name_pass',
    component: () => import('../views/member/certification/name-pass/index'),
    meta: {
      keepAlive: true,
      title: '身份验证成功'
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order/index'),
    meta: {
      title: '订单总数据'
    }
  },
  {
    path: '/order/quoting/detail/worker-quoting/:orderId',
    name: 'quoting',
    component: () => import('../views/order/quoting/detail/worker-quoting/index'),
    meta: {
      title: '用户正在报价'
    }
  },
  {
    path: '/order/quoting/detail/:orderId',
    name: 'quoting_detail',
    component: () => import('../views/order/quoting/detail/index'),
    meta: {
      title: '去报价的详情页'
    }
  },
  {
    path: '/order/accept/detail/:orderId',
    name: 'accept_detail',
    component: () => import('../views/order/quoting/detail/accept'),
    meta: {
      title: '接单详情页'
    }
  },
  {
    path: '/order/quoted/question',
    name: 'quoted_question',
    component: () => import('../views/order/quoted/question/index'),
    meta: {
      title: '已报价相关问题解释'
    }
  },
  {
    path: '/order/quoted',
    name: 'quoted',
    component: () => import('../views/order/quoted/index'),
    meta: {
      title: '已报价订单列表'
    }
  },
  {
    path: '/order/quoted/detail/:orderId',
    name: 'quoted_detail',
    component: () => import('../views/order/quoted/detail/index'),
    meta: {
      title: ' 已报价订单详情'
    }
  },
  {
    path: '/order/appoint',
    name: 'appoint',
    component: () => import("../views/order/appoint/index"),
    meta: {
      title: '待预约订单列表'
    }
  },
  {
    path: '/order/appoint/detail/:orderId',
    name: 'appoint_detail',
    component: () => import("../views/order/appoint/detail/index"),
    meta: {
      title: '待预约订单详情'
    }
  },
  {
    path: '/order/appoint/detail/reservation/:orderId',
    name: 'reservation',
    component: () => import("../views/order/appoint/detail/reservation/index"),
    meta: {
      title: '预约页面'
    }
  },
  {
    path: '/order/sign-in',
    name: 'sign-in',
    component: () => import('../views/order/sign-in/index'),
    meta: {
      title: '待签到订单列表'
    }
  },
  {
    path: '/order/sign-in/detail/:orderId',
    name: 'sign-in-detail',
    component: () => import("../views/order/sign-in/detail/index"),
    meta: {
      title: '待签到订单详情'
    }
  },
  {
    path: '/order/sign-in/detail/position/:orderId',
    name: 'sign-in-position',
    component: () => import("../views/order/sign-in/detail/position/index"),
    meta: {
      title: '签到定位'
    }
  },
  {
    path: '/order/sign-in/detail/position/inspection/:orderId',
    name: 'inspection',
    component: () => import('../views/order/sign-in/detail/position/inspection/index'),
    meta: {
      title: '签到验货'
    }
  },
  {
    path: '/order/refund/:orderId',
    name: 'refund',
    component: () => import('../views/order/refund/index'),
    meta: {
      title: '师傅退款详情'
    }
  },
  {
    path: '/order/refund/handle/:orderId',
    name: 'refund-handle',
    component: () => import('../views/order/refund/handle/index'),
    meta: {
      title: '师傅退款处理'
    }
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('../views/mine/index'),
    meta: {
      title: '我的'
    }
  },
  {
    path: '/mine/worker-store',
    name: 'worker_store',
    component: () => import('../views/mine/worker-store/index'),
    meta: {
      title: '师傅店铺'
    }
  },
  {
    path: '/mine/quality-score',
    name: 'quality_score',
    component: () => import('../views/mine/quality-score/index'),
    meta: {
      title: '服务质量分'
    }
  },
  {
    path: '/mine/quality-score/deduct',
    name: 'deduct_score',
    component: () => import('../views/mine/quality-score/deduct/index'),
    meta: {
      title: '扣分明细'
    }
  },
  {
    path: '/mine/quality-score/ratio',
    name: 'deduct_ratio',
    component: () => import('../views/mine/quality-score/ratio/index'),
    meta: {
      title: '扣率明细'
    }
  },
  {
    path: '/mine/wallet',
    name: 'worker_wallet',
    component: () => import("../views/mine/wallet/index"),
    meta: {
      keepAlive: false,
      title: '我的钱包'
    },
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import("../views/wallet/index"),
    meta: {
      keepAlive: false,
      title: '钱包'
    },
  },
  {
    path: '/mine/wallet/bill',
    name: 'withdraw-bill',
    component: () => import("../views/mine/wallet/bill/index"),
    meta: {
      title: '收支明细'
    },
  },
  {
    path: '/mine/wallet/bank-card',
    name: 'bank-card',
    component: () => import("../views/mine/wallet/bank-card/index"),
    meta: {
      title: '银行卡'
    },
  },
  {
    path: '/mine/wallet/security-deposit',
    name: 'security_deposit',
    component: () => import("../views/mine/wallet/security-deposit/index"),
    meta: {
      title: '诚信保证金'
    },
  },
  {
    path: '/mine/wallet/security-deposit/pay-list',
    name: 'pay-list',
    component: () => import("../views/mine/wallet/security-deposit/pay-list/index"),
    meta: {
      title: '充值记录'
    },
  },
  {
    path: '/mine/wallet/security-deposit/pay',
    name: 'pay',
    component: () => import("../views/mine/wallet/security-deposit/pay/index"),
    meta: {
      title: '保证金充值'
    },
  },
  {
    path: '/mine/wallet/security-deposit/pay-result',
    name: 'pay-result',
    component: () => import("../views/mine/wallet/security-deposit/pay-result/index"),
    meta: {
      title: '充值返回结果页'
    },
  },
  {
    path: '/mine/wallet/withdraw',
    name: 'withdraw',
    component: () => import("../views/mine/wallet/withdraw/index"),
    meta: {
      title: '提现'
    },
  },
  {
    path: '/mine/wallet/withdraw/list',
    name: 'withdraw-list',
    component: () => import("../views/mine/wallet/withdraw/lists/index"),
    meta: {
      title: '提现记录'
    },
  },
  {
    path: '/mine/wallet/withdraw/list/methods',
    name: 'withdraw-methods',
    component: () => import("../views/mine/wallet/withdraw/methods/index"),
    meta: {
      title: '提现方式'
    },
  },


  {
    path: '/mine/wallet/withdraw/list/detail/:orderId',
    name: 'withdraw-detail',
    component: () => import("../views/mine/wallet/withdraw/lists/detail/index"),
    meta: {
      title: '提现详情'
    },
  },
  {
    path: '/mine/personal-info',
    name: 'personal-info',
    component: () => import("../views/mine/worker-data/personal-info/index"),
    meta: {
      title: '个人资料-new'
    },
  },
  {
    path: '/mine/worker-data',
    name: 'worker_data',
    component: () => import("../views/mine/worker-data/index"),
    meta: {
      title: '个人资料'
    },
  },
  {
    path: '/mine/worker-data/base',
    name: 'base_data',
    component: () => import("../views/mine/worker-data/base/index"),
    meta: {
      title: '基本资料'
    }
  },
  {
    path: '/mine/worker-data/serve',
    name: 'serve_data',
    component: () => import("../views/mine/worker-data/serve/index"),
    meta: {
      title: '服务信息'
    }
  },
  {
    path: '/mine/worker-data/promise',
    name: 'promise_data',
    component: () => import("../views/mine/worker-data/promise/index"),
    meta: {
      title: '服务承诺'
    }
  },
  {
    path: '/mine/worker-data/promise/change',
    name: 'change_promise',
    component: () => import("../views/mine/worker-data/promise/change/index"),
    meta: {
      title: '修改服务承诺'
    }
  },
  {
    path: '/mine/worker-data/initialize',
    name: 'initialize_data',
    component: () => import("../views/mine/worker-data/initialize/index"),
    meta: {
      title: '更多信息（登录之后初始化）'
    }
  },
  {
    path: '/mine/set',
    name: 'set',
    component: () => import('../views/mine/set/index'),
    meta: {
      title: '设置'
    }
  },
  {
    path: '/learn',
    name: 'leran',
    component: () => import('../views/learn/index'),
    meta: {
      title: '学习'
    }
  },
  {
    path: '/order/finish',
    name: 'finish_list',
    component: () => import('../views/order/finish/index'),
    meta: {
      title: '待完成订单列表'
    }
  },
  {
    path: '/order/finish/detail/:orderId',
    name: 'finish_detail',
    component: () => import('../views/order/finish/detail/index'),
    meta: {
      title: '待完成订单详情'
    }
  },
  {
    path: '/order/payment',
    name: 'payment_list',
    component: () => import('../views/order/payment/index'),
    meta: {
      title: '待收款订单列表'
    }
  }, {
    path: '/order/payment/detail/:orderId',
    name: 'payment_detail',
    component: () => import('../views/order/payment/detail/index'),
    meta: {
      title: '待收款订单详情'
    }
  },
  {
    path: '/order/success',
    name: 'success_list',
    component: () => import('../views/order/success/index'),
    meta: {
      title: '交易成功订单列表'
    }
  },
  {
    path: '/order/success/detail/:orderId',
    name: 'success_detail',
    component: () => import('../views/order/success/detail/index'),
    meta: {
      title: '交易成功订单详情'
    }
  },
  {
    path: '/order/close',
    name: 'close_list',
    component: () => import('../views/order/close/index'),
    meta: {
      title: '交易关闭订单列表'
    }
  }, {
    path: '/order/close/detail/:orderId',
    name: 'close_detail',
    component: () => import('../views/order/close/detail/index'),
    meta: {
      title: '交易关闭订单详情'
    }
  },
  {
    path: '/order/forget',
    name: 'forget_list',
    component: () => import('../views/order/forget/index'),
    meta: {
      title: '遗留订单列表'
    }
  }, {
    path: '/order/forget/detail/:orderId',
    name: 'forget_detail',
    component: () => import('../views/order/forget/detail/index'),
    meta: {
      title: '遗留订单详情'
    }
  },
  {
    path: '/order/finish/detail/affirm-finished/:orderId',
    name: 'affirm-finished',
    component: () => import('../views/order/finish/detail/affirm-finished/index'),
    meta: {
      title: '确认完工',
      keepAlive: true,
    }
  },
  {
    path: '/order/finish/detail/affirm-finished/list/:orderId',
    name: 'finished_list',
    component: () => import('../views/order/finish/detail/affirm-finished/list/index'),
    meta: {
      title: '电子签收单填写',
      keepAlive: true,
    }
  },
  {
    path: '/order/finish/detail/affirm-finished/list/table/:orderId',
    name: 'finished_table',
    component: () => import('../views/order/finish/detail/affirm-finished/list/table/index'),
    meta: {
      title: '电子签收单生成图片',
      keepAlive: true,
    }
  },
  {
    path: '/effect/upload-other',
    name: 'UploadOther',
    component: () => import('../views/effect/upload-other/index')
  },
  {
    path: '/effect/map',
    name: 'map',
    component: () => import('../views/effect/map')
  },
]
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 重复点击路由会报错问题解决
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// to.name === 'order'
router.beforeEach((to, from, next) => {
  if (to.name === 'home') {
    to.meta.keepAlive = true
  } else {
    from.meta.keepAlive = false
  }
  /**
   * 登录注册 token管理
   */
  const nextRoute = ['login', 'reset_password', 'register'];
  if (nextRoute.indexOf(to.name) >= 0) {
    next();
  } else {
    let token = localStorage.getItem('token');
    if (token === null || token === '') {
      next('/member/login');
    } else {
      next();
    }
  }


})

export default router