<template>
  <div class="loading1" v-show="show" v-if="type == 1">
    <van-loading size="24px" vertical color="#fff"> <span :style="{color:'#fff'}">{{title}}</span></van-loading>
  </div>
  <div class="loading type2" v-else-if="type == 2">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50" xml:space="preserve">
      <rect x="0" y="9.22656" width="4" height="12.5469" fill="#FF6700">
        <animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate>
        <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate>
      </rect>
      <rect x="10" y="5.22656" width="4" height="20.5469" fill="#FF6700">
        <animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate>
        <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate>
      </rect>
      <rect x="20" y="8.77344" width="4" height="13.4531" fill="#FF6700">
        <animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate>
        <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate>
      </rect>
    </svg>
    <p>数据准备中...</p>
  </div>
  <div class="loading type3" v-else-if="type == 3">
    正在加载中啊 全页面
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "加载中",
    },
    show: {
      type: Boolean,
      default: false,
    },
    /** 动画类型
     * 2 页面内等待
     * 3 全页面等待
     *
     */
    type: {
      type: Number,
      default: 1,
    },
  },
};
</script>
<style lang="less">
.loading1 {
  width: 120px;
  height: 120px;
  background: #0009;
  position: fixed;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
  z-index: 999;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  P {
    margin: 0;
  }
}
.loading {
  &.type2 {
    // position: fixed;
    // z-index: 999;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      font-size: 1.4rem;
    }
  }
}
</style>