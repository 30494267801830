import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAMap from 'vue-amap';
import axios from 'axios'
import '../src/assets/public.less'
import base from './base'
import dayjs from "dayjs"

/** 
 * 引入全局组件
 */
import base_components from './base-components.js'
Vue.use(base_components)

/** 
 * 导入公共资源文件url
 */

/**
 *  vant按需引入
 */
import {
  SwipeCell,
  Cell,
  CellGroup,
  Button,
  Icon,
  Popup,
  Toast,
  Checkbox,
  CheckboxGroup,
  Field,
  Form,
  Picker,
  RadioGroup,
  Radio,
  Rate,
  Uploader,
  Dialog,
  DropdownMenu,
  DropdownItem,
  Loading,
  PullRefresh,
  Collapse,
  CollapseItem,
  CountDown,
  Divider,
  Empty,
  ImagePreview,
  List,
  NoticeBar,
  Skeleton,
  Swipe,
  SwipeItem,
  Tag,
  Grid,
  GridItem,
  Sidebar,
  SidebarItem,
  Tab,
  Tabs,
  Area,
  ContactCard,
  SubmitBar,
  NavBar,
  PasswordInput,
  NumberKeyboard,
  Badge,
  Overlay,
  Notify,
  ActionSheet,
  Switch,
  AddressEdit,
  Cascader
} from 'vant';
import {
  Image as VanImage
} from 'vant';
Vue.use(VanImage)
Vue.use(Cell)
Vue.use(Button)
Vue.use(Icon)
Vue.use(CellGroup)
Vue.use(Popup)
Vue.use(Toast)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Field)
Vue.use(Form)
Vue.use(Picker)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Rate)
Vue.use(Uploader)
Vue.use(Dialog);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Loading);
Vue.use(PullRefresh);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(CountDown);
Vue.use(Divider);
Vue.use(Empty);
Vue.use(ImagePreview);
Vue.use(List);
Vue.use(NoticeBar);
Vue.use(Skeleton);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tag);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Area);
Vue.use(ContactCard);
Vue.use(SubmitBar);
Vue.use(NavBar);
Vue.use(SwipeCell);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Badge);
Vue.use(Overlay);
Vue.use(ActionSheet);
Vue.use(Notify);
Vue.use(Switch);
Vue.use(AddressEdit);
Vue.use(Cascader);

// <-------------->
Vue.use(base)

Vue.prototype.dayjs = dayjs;
Vue.prototype.timeout = true

const ajax = axios.create({
  baseURL: 'https://api.yishifu.com',
  retry: 2, // 请求次数
  retryInterval: 10000, // 求期间隙
})

Vue.prototype.$ajax = ajax

/**
 * 全局api 请求封装
 */
// http: //item.fea.cx
// http://12.yishifu.com:8999
const http = axios.create({
  baseURL: 'https://api.yishifu.com',
  retry: 2, // 请求次数
  retryInterval: 10000, // 求期间隙
})
http.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    } else {
      // router.replace({
      //   name: 'login',
      // })
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  });
// http response 拦截器
http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          window.localStorage.clear()
          router.replace({
            name: 'login',
          })
      }
    }
    return Promise.reject(error.response.data) // 返回接口返回的错误信息
  },
);

Vue.prototype.$http = http
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')